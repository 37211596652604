<template>
  <div class="bner">
    <v-img
      :lazy-src="`${url}?imageView2/2/w/1920/h/800`"
      height="800"
      :src="url"
    ></v-img>
    <div class="bner-text-con view_flex_cc">
      <div class="text_center">
        <h1 class="size_60 bigTitle animated rubberBand">{{ title }}</h1>
        <h2 class="size_30">huuungry</h2>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BigTitleBner",
  props: {
    title: {
      type: String,
      default: "标题",
    },
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.bner {
  position: relative;
  height: 800px;
  .bner-text-con {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba($color: #000000, $alpha: 0.6);
    color: #fff;
    .bigTitle {
      margin-bottom: 40px;
    }
  }
}
</style>
