<template>
  <div class="main">
    <BigTitleBner title="CONTACT US" :url="`${this.$imgUrl}img_r_1.png`" />
    <div class="main-con">
      <div class="containerView">
        <div
          class="article-title text_center text_bold"
          v-animate="'animated fadeInUp'"
        >
          <h2 class="size_48">CONTACT US</h2>
          <h3 class="size_24" v-html="$t('contactText.text1')"></h3>
        </div>
        <div class="article-con size_20" v-animate="'animated fadeInUp'">
          <p style="text-align: justify" v-html="aboutUsInfo.description"></p>
        </div>
        <div class="contact-way view_flex_c">
          <div class="text_center way-item" v-animate="'animated fadeInUp'">
            <div class="icon icon1">
              <v-icon large color="#111111"> mdi-phone-in-talk </v-icon>
            </div>
            <div class="text_bold size_24">{{ aboutUsInfo.phone }}</div>
          </div>
          <div class="text_center way-item" v-animate="'animated fadeInUp'">
            <div class="icon icon2">
              <v-icon large color="#111111"> mdi-email </v-icon>
            </div>
            <div class="text_bold size_24">{{ aboutUsInfo.mail }}</div>
          </div>
          <div class="text_center way-item" v-animate="'animated fadeInUp'">
            <div class="icon icon3">
              <v-icon large color="#111111"> mdi-map-marker </v-icon>
            </div>
            <div class="text_bold size_24" v-html="aboutUsInfo.address"></div>
          </div>
        </div>
        <div class="contact-bner" v-animate="'animated fadeInUp'">
          <img :src="`${this.$imgUrl}img_r_2.png`" />
        </div>
        <div
          class="article-title text_center text_bold"
          v-animate="'animated fadeInUp'"
        >
          <h2 class="size_48">CUSTOMEE MESSAGE</h2>
          <h3 class="size_24" v-html="$t('contactText.text4')"></h3>
        </div>
        <div class="form" v-animate="'animated fadeInUp'">
          <div class="view_flex_c view_flex_sb">
            <div class="inputClass">
              <input
                type="text"
                v-model="form.name"
                :placeholder="$t('contactText.text5')"
              />
            </div>
            <div class="inputClass">
              <input
                type="text"
                v-model="form.phone"
                :placeholder="$t('contactText.text6')"
              />
            </div>
          </div>
          <div>
            <textarea
              class="textareaClass"
              v-model="form.message"
              :placeholder="$t('contactText.text7')"
            ></textarea>
          </div>
          <div class="text_center">
            <v-btn
              class="btn-submit"
              :loading="loading"
              :disabled="loading"
              color="#13131D"
              @click="submit"
            >
              {{ $t("contactText.text8") }}
            </v-btn>
          </div>

          <v-dialog v-model="dialog" width="500" hide-overlay>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                {{ $t("contactText.text11") }}
              </v-card-title>
              <v-card-text>
                <div class="size_18" style="padding-top: 20px">
                  {{ text }}
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  :loading="btnLoading"
                  @click="dialog = false"
                >
                  I know
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BigTitleBner from "@/components/BigTitleBner";
import { addmessage } from "@/api/config";
import { mapState } from "vuex";
export default {
  name: "contact",
  data() {
    return {
      loading: false,
      dialog: false,
      text: "请完善表单信息",
      form: {
        name: "",
        phone: "",
        message: "",
      },
      btnLoading: false,
    };
  },
  computed: {
    ...mapState({
      aboutUsInfo: (state) => state.config.ABOUTUSINFO,
    }),
  },
  methods: {
    //检查
    check(form) {
      for (let key in form) {
        //都不为空
        if (!form[key].replace(/\s/g, "")) {
          this.text = this.$t("contactText.text9");
          this.dialog = true;
          return false;
        }
      }
      if (!/^1[3-9]\d{9}$/.test(form.phone)) {
        this.text = this.$t("contactText.text10");
        this.dialog = true;
        return false;
      }
      return true;
    },
    submit() {
      let form = this.form;
      if (this.check(form)) {
        this.btnLoading = true;
        addmessage(form)
          .then(() => {
            this.text = "提交成功！";
            this.dialog = true;
            this.form = {};
            this.btnLoading = false;
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },
  },
  components: {
    BigTitleBner,
  },
};
</script>
<style lang="scss" scoped>
.main-con {
  padding-top: 100px;
  padding-bottom: 100px;
  .containerView {
    width: 1056px;
  }
}

.article-title {
  margin-bottom: 50px;
  > h2 {
    margin-bottom: 10px;
  }
}

.contact-bner {
  height: 423px;
  margin-bottom: 80px;
}

.contact-way {
  margin-top: 86px;
  margin-bottom: 90px;
  > div {
    flex: 1;
    width: 0;
  }
  .way-item {
    position: relative;
  }
  .way-item:last-child::before {
    background: transparent;
  }
  .way-item::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 128px;
    background: #888;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
  }
  .icon {
    margin-bottom: 30px;
  }
}

.way-item:hover {
  .icon1 i {
    color: green !important;
  }
  .icon2 i {
    color: orange !important;
  }
  .icon3 i {
    color: palevioletred !important;
  }
}

.form {
  .inputClass,
  .textareaClass {
    width: 516px;
    height: 60px;
    background: #eeeeee;
    opacity: 1;
    border-radius: 4px;
    > input {
      width: 100%;
      height: 100%;
      padding-left: 35px;
      box-sizing: border-box;
    }
  }
  .textareaClass {
    width: 100%;
    min-height: 262px;
    margin: 50px 0;
    padding: 22px 35px;
    box-sizing: border-box;
  }
  .btn-submit {
    width: 340px;
    height: 60px;
    color: #fff;
    font-size: 22px;
  }
}
</style>
